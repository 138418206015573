<template>
  <div>
    <div class="row d-flex justify-content-start align-content-center">
      <router-link
        v-for="(item, index) in mobileMenu"
        :key="index"
        :to="item.link"
        class="mt-2"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item d-flex justify-content-center align-content-center col-4"
        >
          <a
            :href="href"
            class="menu-link justify-content-center text-center pb-1"
            @click="navigate"
          >
            <span v-html="getIconByKey(item.icon_2, { class: 'w-95px h-85px ' })"></span
            ><br />
            <span class="menu-text mb-3 font-weight-bold">
              {{ $t(item.text) }}
            </span>
          </a>
        </li>
      </router-link>
      <section id="main-carousel" class="splide">
        <div class="splide__track">
          <ul class="splide__list">
            <li class="splide__slide">
              <div class="row mb-7">
                <div class="col-2"><span v-html="getIconByKey('icons.menu.meting', { class: 'w-50px h-40px ' })"></span></div>
                <div class="col-10">Şu an müşteriniz River Islan ile bir görüşme gerçekleştirmektedir</div>
              </div>
            </li>
            <li class="splide__slide">
              <div class="row mb-7">
                <div class="col-2"><span v-html="getIconByKey('icons.menu.meting', { class: 'w-50px h-40px ' })"></span></div>
                <div class="col-10">Şu an müşteriniz River Islan ile bir görüşme gerçekleştirmektedir 2</div>
              </div>
            </li>
            <li class="splide__slide">
              <div class="row mb-7">
                <div class="col-2"><span v-html="getIconByKey('icons.menu.meting', { class: 'w-50px h-40px ' })"></span></div>
                <div class="col-10">Şu an müşteriniz River Islan ile bir görüşme gerçekleştirmektedir 3</div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
import Splide from "@splidejs/splide";
import $ from "jquery";

export default {
  name: "dashboard",
  components: {
    AdvancedTableWidget2,
    MixedWidget1,
    ListWidget1,
    ListWidget3,
    ListWidget4,
    ListWidget8,
    ListWidget9,
    StatsWidget7,
    StatsWidget12,
  },
  data() {
    return {
      mediaBase: process.env.VUE_APP_MEDIA_URL,
      baseUrl: process.env.VUE_APP_APP_URL,
    };
  },
  beforeRouteLeave(to, from, next) {
    let ktContent = $(".quick-panel-right");
    ktContent.css({
      backgroundImage: "none",
      "background-size": "cover",
    });
    next();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    let ktContent = $(".quick-panel-right");
    console.log(this.mediaBase);
    let bgImage = this.mediaBase + "/bg/MOBIL.png";
    ktContent.css({
      backgroundImage: "url('" + bgImage + "')",
      "background-size": "cover",
    });
    new Splide(".splide", {
      arrows: false,
      width: "95%",
    }).mount();
  },
  computed: {
    mobileMenu() {
      return this.authUser ? this.authUser.mobile_menu : null;
    },
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
<style>
/* Splide.js stilleri */
@import "~@splidejs/splide/dist/css/splide.min.css";
</style>
<style scoped lang="scss">
li {
  list-style: none !important;
  a {
    border: 0.18rem solid rgba(92, 92, 92, 0.93) !important;
    width: 100%;
    height: auto;
    border-radius: 5px;
    background-color: rgb(0 0 0 / 46%);
    color: white;
  }
}
#main-carousel {
  width: 95%;
  border: 2px solid rgba(58, 133, 192, 0.3215686275);
  border-radius: 10px;
  position: fixed;
  bottom: 10%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: rgb(68 151 255 / 36%);
}



.splide__slide {
  width: 100%; /* Parent elementin genişliğini kaplar */
  color: white;
  font-size: 11px;
}
</style>
